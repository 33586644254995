import { getApiV3RoadmapCategories } from 'components/api';
import { TOPIC_SCENE, TOPIC_TYPE } from 'components/constants/roadmap';
import React, { Component } from 'react';
import { Loading, Select } from 'tdesign-react';
import './style.less';
interface SelectBoardStateProps {
  productId: string
  isMini: boolean
  /** 默认激活的value */
  defaultValue?: TOPIC_TYPE | number;
}

interface SelectBoardStateState {
  doingOptions: OpetionType[];
  activeOption: OpetionType | null;
}

interface OpetionType {
  name: string;
  value: TOPIC_TYPE | number;
  level: number;
  color: string;
}
const { Option, OptionGroup } = Select;
const DONE_OPTION = { name: '已完成', value: TOPIC_TYPE.DONE, level: 1, color: '#9CDA4B' };
const CLOSED_OPTION = { name: '已关闭', value: TOPIC_TYPE.CLOSED, level: 1, color: '#E3E3E3' };

const defaultOptions: OpetionType[] = [
  DONE_OPTION,
  CLOSED_OPTION,
];


class SelectBoardState extends Component<SelectBoardStateProps, SelectBoardStateState> {
  constructor(props) {
    super(props);
    this.state = {
      doingOptions: [],
      activeOption: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { defaultValue } = this.props;

    getApiV3RoadmapCategories({
      productId: this.props.productId,
      scene: TOPIC_SCENE.TYPE_ALL,
      type: TOPIC_TYPE.DOING,
    })
      .then((res) => {
        const doingOptions: OpetionType[] = res.data.doing.groups.map(topic => ({
          name: topic.title,
          value: topic.id,
          level: 2,
          color: topic.setting.color || '#e96f27',
        }));

        let activeOption;

        if (defaultValue) {
          if (defaultValue === TOPIC_TYPE.CLOSED) {
            activeOption = CLOSED_OPTION;
          } else if (defaultValue === TOPIC_TYPE.DONE) {
            activeOption = DONE_OPTION;
          } else {
            activeOption = doingOptions.find(option => option.value === defaultValue);
          }
        }
        // 没有默认 或者找不到
        if (!activeOption) {
          activeOption = doingOptions[0] || defaultOptions[0];
        }

        this.setState({
          doingOptions,
          activeOption,
        });
      });
  }

  onChange = (value: TOPIC_TYPE | number) => {
    let activeOption;
    if (value === TOPIC_TYPE.CLOSED) {
      activeOption = CLOSED_OPTION;
    } else if (value === TOPIC_TYPE.DONE) {
      activeOption = DONE_OPTION;
    } else {
      const { doingOptions } = this.state;
      activeOption = doingOptions.find(option => option.value === value);
    }

    if (activeOption) {
      this.setState({
        activeOption,
      });
    }
  }

  getActiveValue = () => this.state.activeOption

  handleClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { doingOptions, activeOption } = this.state;
    const { isMini } = this.props;
    const width = isMini ? '100px' : '200px';
    if (!activeOption) {
      return <Loading />;
    }
    return (
      <div className="select-group" onClick={this.handleClick} onKeyDown={this.handleClick} role="button" tabIndex={0}>
        <Select
          value={activeOption.name}
          onChange={this.onChange}
          autoWidth
          style={{ maxWidth: width }}
          prefixIcon={isMini ? undefined : <div className="select-group__spheric" style={{ background: activeOption.color || '#e96f27' }} />}
        >
          {doingOptions.length > 0 && (
            <OptionGroup label="进行中" divider={true}>
              {doingOptions.map((option, idx) => (
                <Option key={idx} value={option.value} label={option.name} >
                  <div className={`select-group__list ${option.level === 1 ? 'select-group__list--olevel' : 'select-group__list--tlevel'}`}>
                    <span className="select-group__icon" style={{ backgroundColor: option.color }}></span>
                    {option.name}
                  </div>
                </Option>
              ))}
            </OptionGroup>
          )}
          {defaultOptions.map((option, idx) => (
            <Option key={idx} value={option.value} label={option.name}>
              <div className={`select-group__list ${option.level === 1 ? 'select-group__list--olevel' : 'select-group__list--tlevel'}`}>
                <span className="select-group__icon" style={{ backgroundColor: option.color }}></span>
                {option.name}
              </div>
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
export default SelectBoardState;

import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { Loading } from '@tencent/ten-design-react';
import { handleKeyDown } from 'components/util/aria/handleKeyDown';
import './style.less';
import CreateTopic from '../create-topic';
import { LoadMore } from 'components/load-more';

export const SearchTopic = ({
  topics = [], onChange, onClick = () => {}, onCreate, visible,
  showLoadMore, isLoading, showCreateTopic, onLoadMore = () => {},
  productId,
}) => {
  const [inputTopic, setInputTopic] = useState('');
  const inputRef = useRef();
  // 这里做一个防抖
  const handleChange = debounce(() => {
    onChange(inputRef.current?.value);
    setInputTopic(inputRef.current?.value);
  }, 800, { trailing: true });

  const handleItemClick = topic => () => {
    if (typeof onClick !== 'function') return;
    onClick({ id: topic.id, title: topic.title });
  };

  const handleCreateTopic = (topic) => {
    inputRef.current.value = '';
    setInputTopic('');
    onCreate({ id: topic.id, title: topic.title });
  };

  return (
    <div className="search-topic">
      <div className="search-topic__editor-wrap">
        <div className="search-topic__editor">
          <input
            type="text"
            className="search-topic__input"
            placeholder="搜索投票"
            onChange={handleChange}
            ref={inputRef}
          />
        </div>
      </div>
      <div className="search-topic__list-wrap">
        {isLoading && (<Loading />)}
        <div className={classnames('search-topic__list', {
          'search-topic__list--empty': !isLoading && topics.length === 0,
        })}>
          {topics && topics.map(topic => (
            <div
              className={classnames('search-topic__item', { 'search-topic__recommend': topic?.isRecommended })}
              key={topic.id}
              onClick={handleItemClick(topic)}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown(handleItemClick(topic))}
            >
              <div className="search-topic__item-title">{topic.title}</div>
              {topic.stat && (
                <div className="search-topic__item-ino">
                  {topic.stat.read_count || 0}阅读 {topic.stat.content_count || 0}讨论 {/* {topic.likeCount || 0}顶 */}
                </div>
              )}
            </div>
          ))}
          {!isLoading && topics.length === 0 && (
            <div className="search-topic__empty">还没有相关话题</div>
          )}
          {showLoadMore && (<LoadMore onShow={onLoadMore} />)}
        </div>
      </div>

      {showCreateTopic && (
        <CreateTopic
          inputTopic={inputTopic}
          onCreate={handleCreateTopic}
          refresh={visible}
          productId={productId}
        />
      )}
    </div>
  );
};

SearchTopic.propTypes = {
  topics: PropTypes.array,
  isLoading: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  onChange: PropTypes.func,
  onLoadMore: PropTypes.func,
  onClick: PropTypes.func,
  onCreate: PropTypes.func,
  showCreateTopic: PropTypes.bool,
  visible: PropTypes.bool,
  productId: PropTypes.string,
};

export default SearchTopic;

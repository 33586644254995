import React, { FC, useState, useRef, useEffect } from 'react';
import { Input, Button } from 'tdesign-react';
import { handleKeyDown } from 'components/util/aria/handleKeyDown';
import { postApiV3RoadmapTopics } from 'components/api';
import './style.less';
import SelectBoardState from 'components/select-board-state';
import { TOPIC_TYPE } from 'components/constants/roadmap';
import { PostApiV3RoadmapTopicsReq } from 'components/api/v3/roadmap/topics';

interface Topic {
  id: number;
  title: string;
}
interface CreateTopicProps {
  /** 用户输入的话题 */
  inputTopic: string;
  /** 成功创建的响应事件 */
  onCreate: (topic: Topic) => void;
  /** 刷新关键词 */
  refresh: boolean;
  /** 这里后台也要用，需要手动传 产品ID */
  productId: string;
}
const CreateTopic: FC<CreateTopicProps> = ({ inputTopic, onCreate, refresh, productId }) => {
  const title = useRef(inputTopic);
  const selectGroupRef = useRef<SelectBoardState>(null);
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    setShowEditor(false);
  }, [refresh]);

  const handleCreate = () => {
    if (inputTopic) {
      handleShowCreate();
    } else {
      setShowEditor(true);
    }
  };

  const handleShowCreate = () => {
    //  这里提交的标题如果有打开编辑器用编辑器输入， 没有打开编辑器用搜索输入
    const content = showEditor ? title.current : inputTopic;
    const topic = selectGroupRef.current?.getActiveValue();
    const param: PostApiV3RoadmapTopicsReq = { productId, title: content };
    if (
      topic?.value === TOPIC_TYPE.CLOSED
      || topic?.value === TOPIC_TYPE.DONE
      || topic?.value === TOPIC_TYPE.DOING
    ) {
      param.type = topic.value;
    } else {
      param.type = TOPIC_TYPE.DOING;
      param.groupId = topic?.value;
    }

    postApiV3RoadmapTopics(param)
      .then((res) => {
        onCreate({ id: res.data.id, title: content });
      });
  };


  const handleCancel = () => {
    setShowEditor(false);
  };
  const handleConfirm = () => {
    handleShowCreate();
  };

  const handleInputChange = (value) => {
    title.current = value;
  };

  return (
    <div className="create-topic" >
      {/* 预览区域 */}

      <div
        className="create-topic__create"
        onClick={handleCreate}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown(handleCreate)}
        // 这里只移除可见性， 不移除节点
        style={{ display: showEditor ? 'none' : 'flex' }}
      >

        <span className="create-topic__title">创建投票主题  </span>
        <span className="create-topic__content">{inputTopic}</span>
        <SelectBoardState
          productId={productId}
          ref={selectGroupRef}
          isMini
        />
      </div>


      {/* 编辑区域 */}
      {showEditor && (
        <div className="create-topic__editor">
          <Input
            className="create-topic__input"
            onChange={handleInputChange}
            autofocus
            maxlength={20}
            placeholder="投票主题名称"
            onEnter={handleConfirm}
          />
          <Button
            theme="default"
            className="create-topic__cancel"
            onClick={handleCancel}
          >
            取消
          </Button>
          <Button
            theme="primary"
            className="create-topic__confirm"
            onClick={handleConfirm}
          >
            确定
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateTopic;

/**
 * 话题选择
 */
import React, {   Component } from 'react';
import { Popup } from 'tdesign-react';
import message from '@tencent/ten-design-react/lib/message';
import PropTypes from 'prop-types';
import { Topic } from 'components/topic';
import { TOPIC_TYPE, TOPIC_SCENE, TOPIC_TAB } from 'components/constants/roadmap';
import { SearchTopic } from 'components/select-topic/search-topic';
import { Collect } from 'components/report';
import {
  getApiV3RoadmapTopics,
  getApiV3RoadmapTopicSearch,
} from 'components/api';
import './style.less';


const cls = 'select-topic';
export class SelectTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /** 当前产品的话题列表 */
      productTopics: null,
      // 热门话题
      hotTopics: null,
      activeTopic: props.defaultTopic || null,
      // 用户输入内容
      userContent: '',
      /** 展示话题选择面板 */
      showSearchTopic: false,
      pagination: {},
    };
    this.isLoadingMore = false;
  }

  componentDidMount() {
    if (this.props.productId) {
      this.uploadHotTopic(this.props.productId);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.productId && (nextProps.productId !== this.props.productId)) {
      this.resetTopic();
      this.uploadHotTopic(nextProps.productId);
    }
  }

  // 加载产品热门话题
  uploadHotTopic = (productId) => {
    getApiV3RoadmapTopics({ productId, tab: TOPIC_TAB.POPULAR, scene: TOPIC_SCENE.TYPE_PUBLIC_STAT })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ hotTopics: res.data, pagination: res.pagination });
        }
      });
  }

  // 重设话题
  resetTopic = () => {
    this.setState({
      productTopics: null,
      hotTopics: null,
      activeTopic: null,
      userContent: '',
      showSearchTopic: false,
    });
  }

  // 点击话题时弹出话题选择框重选择投票
  handleTopicItemClick = () => {
    const { onItemClick } = this.props;
    const { activeTopic } = this.state;
    if (onItemClick) {
      onItemClick(activeTopic);
      return;
    }

    this.setState(({ showSearchTopic }) => ({ showSearchTopic: !showSearchTopic }));
  }

  // 点击删除话题按钮时移除对应话题
  handleTopicItemCancel = (topic) => {
    const { onCancel } = this.props;
    if (!topic || !topic.id) return;
    this.setState({ activeTopic: null });
    onCancel && onCancel(topic);
  }

  handleSearchChange = (userContent) => {
    this.setState({ userContent, productTopics: null });

    getApiV3RoadmapTopicSearch({
      productId: this.props.productId,
      keyword: userContent,
      scene: TOPIC_SCENE.STAT,
    })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ productTopics: res.data });
        }
      });
  }

  // 处理搜索投票的点击
  handleSearchClick = (topic) => {
    if (!topic || !topic.id) return;
    const { onChange } = this.props;

    this.setState({ activeTopic: topic, showSearchTopic: false });
    onChange && onChange(topic);
  }

  changeSearchTopic = () => {
    const { showSearchTopic } = this.state;
    const { productId } = this.props;
    if (!showSearchTopic && !productId) {
      new Collect({ ea: 'SELECT_TOPIC_NO_PRODUCT' }).report();
      message.error('请先选择产品');
      return;
    }
    this.setState(({ showSearchTopic }) => ({ showSearchTopic: !showSearchTopic }));
  }

  getActiveTopic = () => this.state.activeTopic

  /**
   * 创建话题，手动更新热门话题
   */
  handleCreateTopic = (topic) => {
    if (!topic || !topic.id) return;
    const { onChange } = this.props;

    this.setState({
      activeTopic: topic,
      showSearchTopic: false,
      userContent: '',
    });
    this.uploadHotTopic(this.props.productId);
    onChange && onChange(topic, true);
  }

  // 话题分页
  handleLoadMoreTopic = () => {
    const { pagination } = this.state;
    if (this.isLoadingMore || !pagination.has_more_pages) return;
    this.isLoadingMore = true;

    getApiV3RoadmapTopics({
      productId: this.props.productId,
      tab: TOPIC_TAB.POPULAR,
      scene: TOPIC_SCENE.TYPE_PUBLIC_STAT,
      nextPageId: pagination.next_page_id,
    })
      .then((res) => {
        this.isLoadingMore = false;
        this.setState(({ hotTopics }) => ({
          hotTopics: [...hotTopics, ...res.data],
          pagination: res.pagination,
        }));
      })
      .catch(() => {
        this.isLoadingMore = false;
      });
  }

  renderSeach() {
    const { isAdmin } = this.props;
    const { showSearchTopic, productTopics, userContent, hotTopics, pagination } = this.state;
    // 话题列表展示规则： 用户没输入展示 热门话题， 用户已输入 则展示产品话题列表搜索结果
    const topicList = userContent ? productTopics : hotTopics;

    const isLoading = topicList === null;
    return (
      <SearchTopic
        onClick={this.handleSearchClick}
        onCreate={this.handleCreateTopic}
        onLoadMore={this.handleLoadMoreTopic}
        isLoading={isLoading}
        onChange={this.handleSearchChange}
        showLoadMore={!userContent && pagination.has_more_pages}
        topics={topicList}
        visible={showSearchTopic}
        showCreateTopic={isAdmin}
        productId={String(this.props.productId)}
      />
    );
  }

  changeVisibleChange = (visible) => {
    const { showSearchTopic } = this.state;
    const { productId } = this.props;
    if (!showSearchTopic && !productId) {
      return;
    }
    this.setState({ showSearchTopic: visible });
  }

  render() {
    const { activeTopic, showSearchTopic } = this.state;
    return (
      <Popup
        className={cls}
        overlayClassName={`${cls}__overlay`}
        attach="body"
        content={this.renderSeach()}
        destroyOnClose={false}
        placement="bottom-left"
        hideEmptyPopup={true}
        showArrow={false}
        trigger="click"
        visible={showSearchTopic}
        onVisibleChange={this.changeVisibleChange}
      >
        {!activeTopic && (<Topic topic={{ id: 1, title: '选择投票' }} isDefault onClick={this.changeSearchTopic} />)}
        {activeTopic && (
          <Topic
            key={activeTopic.id}
            topic={activeTopic}
            onClick={this.handleTopicItemClick}
            onCancel={this.handleTopicItemCancel}
            showCancel
          />
        )}
      </Popup>
    );
  }
}

SelectTopic.propTypes = {
  productId: PropTypes.string,
  defaultTopic: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onItemClick: PropTypes.func,
  /** 是否管理员 */
  isAdmin: PropTypes.bool,
};
